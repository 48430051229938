import React, { useEffect, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { makeRequest } from "../../../assets/functions_helper";
import { Box, CircularProgress, DialogContentText } from "@mui/material";
import { useParams } from "react-router-dom";
import { CloseButton, SendButton } from "../../../components/buttons";
import {
  CustomDialog,
  CustomDialogActions,
  CustomDialogContent,
  CustomDialogTitle,
} from "../../../components/customDialog";
import { CustomTextField } from "../../../components/textFields";
import TextLogo from "../../../assets/images/text-logo-calls.png";
import LogoLogo from "../../../assets/images/logo-calls.png";
import { useNavigate } from "react-router-dom";

// Componente de alerta personalizado para Snackbar
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const NotifyButton = () => {
  const [open, setOpen] = useState(false);
  const [openModalCode, setOpenModalCode] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [tableCode, setTableCode] = useState("");
  const [requireCode, setRequireCode] = useState(false);
  const [loading, setLoading] = useState(false);

  const [countDown, setCountDown] = useState(false);
  const [initialCount, setInitialCount] = useState(0);

  const [commentTable, setCommentTable] = useState("");
  const [waiterNotified, setWaiterNotified] = useState(false);

  let { tableNumber } = useParams();

  const navigate = useNavigate();
  
  const handleRedirect = () => {
    navigate(`/restaurantMenu/${tableNumber}`);
  };

  useEffect(() => {
    makeRequest(
      "POST",
      `restaurants/validate_restaurant_require_code/${tableNumber}`,
      {},
      "application/json",
      "",
      "",
      null,
      null,
      () => {}
    )
      .then((responseValidate) => {
        makeRequest(
          "POST",
          `restaurants/validate_restaurant_code/${tableNumber}?code=${localStorage.getItem(
            "tableCode"
          )}`,
          {},
          "application/json",
          localStorage.getItem("token"),
          localStorage.getItem("tokenType"),
          null,
          null,
          () => {}
        )
          .then((response) => {
            if (responseValidate.require_code) {
              setRequireCode(true);
              setOpenModalCode(false);
            }
            setLoading(true);
          })
          .catch((error) => {
            if (responseValidate.require_code) {
              setRequireCode(true);
              setOpenModalCode(true);
            }
            setLoading(true);
          });
      })
      .catch((error) => {
        setLoading(true);
      });
  }, [tableNumber]);

  useEffect(() => {
    if (countDown && initialCount > 0) {
      const timer = setInterval(() => {
        setInitialCount((prevCount) => prevCount - 1);
      }, 1000);
      return () => clearInterval(timer);
    }

    if (initialCount === 0) {
      setCountDown(false);
    }
  }, [initialCount, countDown]);

  const notifyWaiter = async () => {
    if (localStorage.getItem("tableCode") === "") {
      setAlertMessage("Ingrese el código de la mesa");
      setAlertSeverity("error");
      setOpen(true);
      return;
    }
    let url = `notify_waiters/${tableNumber}`;
    if (requireCode) {
      url = `notify_waiters/${tableNumber}`;
    }

    let obj = {
      table_public_id: tableNumber ? tableNumber : "3test",
      code: localStorage.getItem("tableCode"),
      comment: "",
      latitude: 12,
      longitude: 12,
      call_type: "normal_call",
      order: {
        products: [],
        total_price: 0,
      },
    };

    try {
      makeRequest(
        "POST",
        url,
        obj,
        "application/json",
        localStorage.getItem("token"),
        localStorage.getItem("tokenType"),
        null,
        null,
        () => {}
      )
        .then((response) => {
          setAlertMessage("Mesero notificado");
          setAlertSeverity("success");
          setOpen(true);
          setCommentTable("");
          setWaiterNotified(true);
        })
        .catch((error) => {
          if (error.error_code === "0005") {
            setAlertMessage(
              `Para volver a llamar al mesero, espere ${error.value} segundos.`
            );
            setAlertSeverity("success");
            setOpen(true);
            setCountDown(true);
            setInitialCount(error.value);
          } else if (error.error_code === "0006") {
            setAlertMessage("Es codigo es invalido.");
            setAlertSeverity("error");
            setOpen(true);
            setOpenModalCode(true);
          } else {
            console.log(error);
            setAlertMessage("Error al notificar al mesero");
            setAlertSeverity("error");
            setOpen(true);
          }
        });
    } catch (error) {
      console.log(error);
      setAlertMessage("Error al notificar al mesero");
      setAlertSeverity("error");
      setOpen(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      {loading ? (
        <Box
          sx={{
            backgroundColor: "#070724",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: "100vh",
            }}
          >
            <Box
              sx={{
                background: "rgba(255, 255, 255, 0.08)",
                boxShadow: "0px 0px 6px rgba(255, 255, 255, 0.15) inset",
                borderRadius: 15,
                border: "0.50px rgba(255, 255, 255, 0.70) solid",
                backdropFilter: "blur(10px)",
                padding: "20px",
                width: "500px",
                height: "600px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
                '@media (max-width:550px)': {
                  width: "400px",
                  height: "500px",
                },
                '@media (max-width:400px)': {
                  width: "320px",
                  height: "500px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  src={LogoLogo}
                  alt="Logo"
                  style={{ width: "75.79", height: "72.39" }}
                />
                <img
                  src={TextLogo}
                  alt="Logo"
                  style={{ width: "75.79", height: "72.39" }}
                />
              </Box>

              <CustomTextField
                autoFocus
                margin="dense"
                id="tableComment"
                label="Comentario"
                type="text"
                onChange={(e) => setCommentTable(e.target.value)}
                value={commentTable}
                multiline
                minRows={10}
                InputProps={{
                  sx: {
                    alignItems: "flex-start",
                  },
                  inputProps: {
                    sx: {
                      padding: "10px",
                      boxSizing: "border-box",
                    },
                  },
                }}
                sx={{
                  width: "80%",
                }}
              />
              <SendButton
                variant="contained"
                color="primary"
                onClick={notifyWaiter}
                sx={{
                  width: "80%",
                }}
              >
                Llamar al mesero
              </SendButton>
              <SendButton
                variant="contained"
                color="primary"
                onClick={handleRedirect}
                sx={{
                  width: "80%",
                }}
              >
                Ver al Menu
              </SendButton>
            </Box>
            {requireCode && (
              <CustomDialog
                open={openModalCode}
                onClose={() => setOpenModalCode(!openModalCode)}
              >
                <CustomDialogTitle>Digite el Codigo</CustomDialogTitle>
                <CustomDialogContent>
                  <DialogContentText>
                    Por favor, digite el codigo del dia para el restaurante.
                  </DialogContentText>

                  <CustomTextField
                    autoFocus
                    margin="dense"
                    id="tableCode"
                    label="Codigo de la mesa"
                    type="text"
                    onChange={(e) => setTableCode(e.target.value)}
                    value={tableCode}
                    fullWidth
                  />
                </CustomDialogContent>

                <CustomDialogActions>
                  <CloseButton onClick={() => setOpenModalCode(!openModalCode)}>
                    Cerrar
                  </CloseButton>
                  <SendButton
                    onClick={() => {
                      if (tableCode === "") {
                        setAlertMessage("Ingrese el código de la mesa");
                        setAlertSeverity("error");
                        setOpen(true);
                        return;
                      }

                      localStorage.setItem("tableCode", tableCode);
                      setOpenModalCode(false);
                    }}
                  >
                    Enviar
                  </SendButton>
                </CustomDialogActions>
              </CustomDialog>
            )}

            <CustomDialog open={countDown} onClose={() => setCountDown(false)}>
              <CustomDialogTitle>¡¡ATENCIÓN!!</CustomDialogTitle>
              <CustomDialogContent>
                <DialogContentText>
                  Espere {initialCount < 0 ? 0 : initialCount} segundos, antes de llamar a su mesero nuevamente.
                </DialogContentText>
              </CustomDialogContent>
              <CustomDialogActions>
                <CloseButton onClick={() => setCountDown(false)}>
                  Cerrar
                </CloseButton>
              </CustomDialogActions>
            </CustomDialog>

            <CustomDialog open={waiterNotified} onClose={() => setWaiterNotified(false)}>
              <CustomDialogTitle>¡LLAMADA EXITOSA!</CustomDialogTitle>
              <CustomDialogContent>
                <DialogContentText>
                  Su mesero ha sido notificado.
                </DialogContentText>
              </CustomDialogContent>
              <CustomDialogActions>
                <CloseButton onClick={() => setWaiterNotified(false)} sx={{
                  backgroundColor: "#00a152",
                  color: "#ffffff",
                  '&:hover': {
                    backgroundColor: '#00a152',
                    transform: 'scale(1.05)',
                  }
                }}>
                  ¡¡OK!!
                </CloseButton>
              </CustomDialogActions>
            </CustomDialog>
          </Box>

          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={alertSeverity}>
              {alertMessage}
            </Alert>
          </Snackbar>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default NotifyButton;
