"use client";

import { useState, useRef, useEffect } from "react";
import { Button } from "../../../../components/tailwind_componentes/Button";
import { Label } from "../../../../components/tailwind_componentes/Label";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../../components/tailwind_componentes/Card";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "../../../../components/tailwind_componentes/Dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../components/tailwind_componentes/Select";
import { TooltipProvider } from "../../../../components/tailwind_componentes/Tooltip";
import { makeRequest } from "../../../../assets/functions_helper";
import { CloseButton, SendButton } from "../../../../components/buttons";
import { CircularProgress } from "@mui/material";
import { openDB } from "idb";

// Configuración de la base de datos para almacenar colores de menús
const setupDatabase = async () => {
  return openDB("MenuColorsDB", 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains("colors")) {
        db.createObjectStore("colors", { keyPath: "menuId" });
      }
    },
  });
};

// Guardar el color de un menú en IndexedDB
const saveMenuColor = async (menuId, color) => {
  const db = await setupDatabase();
  await db.put("colors", { menuId, color });
};

// Obtener el color de un menú desde IndexedDB, o asignar uno nuevo si no existe
const getMenuColor = async (menuId) => {
  const db = await setupDatabase();
  const storedColor = await db.get("colors", menuId);

  if (storedColor) {
    return storedColor.color;
  }

  const newColor =
    colorPalette[Math.floor(Math.random() * colorPalette.length)];
  await saveMenuColor(menuId, newColor); // Guardar el nuevo color asignado
  return newColor;
};

// Tu arreglo de colores predefinidos
const colorPalette = ["#F1F3F9", "#5A4E99", "#573E8E", "#B0D0EA", "#5D57A0"];

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

function TutorialAnimation() {
  const [step, setStep] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setStep((prevStep) => (prevStep + 1) % 7);
    }, 2000);
    return () => clearInterval(timer);
  }, []);

  const menus = [
    { name: "Breakfast", color: "#FF9999" },
    { name: "Lunch", color: "#99FF99" },
    { name: "Dinner", color: "#9999FF" },
  ];

  return (
    <div className="relative w-full h-48 bg-gray-800 rounded-lg overflow-hidden">
      <style jsx>{`
        @keyframes dropdownAppear {
          0% {
            opacity: 0;
            transform: translateY(-10px);
          }
          100% {
            opacity: 1;
            transform: translateY(0);
          }
        }
        @keyframes optionSelect {
          0%,
          100% {
            background-color: transparent;
          }
          50% {
            background-color: rgba(255, 255, 255, 0.1);
          }
        }
        @keyframes cellPaint {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        @keyframes arrowMove {
          0% {
            transform: translate(0, 0) rotate(-45deg);
          }
          50% {
            transform: translate(2px, 2px) rotate(-45deg);
          }
          100% {
            transform: translate(0, 0) rotate(-45deg);
          }
        }
        @keyframes arrowClick {
          0%,
          100% {
            transform: scale(1) rotate(-45deg);
          }
          50% {
            transform: scale(0.8) rotate(-45deg);
          }
        }
        .dropdown {
          animation: dropdownAppear 0.5s ease-out;
        }
        .option {
          transition: background-color 0.3s ease;
        }
        .option:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }
        .cell {
          transition: background-color 0.3s ease;
        }
        .arrow {
          position: absolute;
          width: 16px;
          height: 16px;
          background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white"><path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"/></svg>');
          background-size: contain;
          transform: rotate(-45deg);
          pointer-events: none;
          z-index: 10;
          transition: all 0.5s ease;
        }
      `}</style>
      <div className="p-2">
        <div className="dropdown mb-2">
          <div className="bg-gray-700 p-1 rounded cursor-pointer text-xs">
            Select a menu
          </div>
          {step >= 1 && (
            <div className="mt-1 bg-gray-700 rounded overflow-hidden">
              {menus.map((menu, index) => (
                <div
                  key={menu.name}
                  className={`option p-1 flex items-center text-xs ${
                    step >= 2 && index === 1 ? "bg-gray-600" : ""
                  }`}
                >
                  <div
                    className="w-3 h-3 rounded-full mr-1"
                    style={{ backgroundColor: menu.color }}
                  ></div>
                  <span>{menu.name}</span>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="grid grid-cols-7 gap-0.5">
          {Array.from({ length: 35 }).map((_, index) => (
            <div
              key={index}
              className={`cell h-6 rounded ${
                (step >= 4 && index === 8) ||
                (step >= 5 && index >= 8 && index <= 13)
                  ? "animate-[cellPaint_0.5s_ease-in-out_forwards]"
                  : "bg-gray-600"
              }`}
              style={{
                backgroundColor:
                  (step >= 4 && index === 8) ||
                  (step >= 5 && index >= 8 && index <= 13)
                    ? menus[1].color
                    : undefined,
              }}
            ></div>
          ))}
        </div>
      </div>
      <div
        className="arrow"
        style={{
          left:
            step === 0
              ? "10px"
              : step === 1
              ? "20px"
              : step === 2
              ? "20px"
              : step === 3
              ? "60px"
              : step >= 4
              ? `${60 + (step - 4) * 90}px`
              : "60px",
          top:
            step === 0
              ? "10px"
              : step === 1
              ? "40px"
              : step === 2
              ? "70px"
              : step >= 3
              ? "130px"
              : "70px",
          animation:
            step === 2 || step === 4 || step === 5
              ? "arrowClick 0.5s"
              : "arrowMove 1s infinite",
        }}
      ></div>
    </div>
  );
}

export default function WeeklyMenuScheduler() {
  const [menus, setMenus] = useState();
  const [schedules, setSchedules] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [rangeStart, setRangeStart] = useState(null);
  const [hoveredCell, setHoveredCell] = useState(null);
  const [clearMode, setClearMode] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [showTutorial, setShowTutorial] = useState(true);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false); // Estado de carga para "Save Changes"
  const [notification, setNotification] = useState(null); // Estado para la notificación
  const [isNotificationOpen, setIsNotificationOpen] = useState(false); // Estado para mostrar/ocultar el Dialog de notificación

  const scheduleGridRef = useRef(null);

  useEffect(() => {
    const fetchMenusWithColors = async () => {
      try {
        const response = await makeRequest(
          "GET",
          "menu_manager/view",
          null,
          "application/json",
          localStorage.getItem("token"),
          localStorage.getItem("tokenType"),
          () => {}
        );

        const updatedResponse = await Promise.all(
          response.map(async (menu) => {
            const color = await getMenuColor(menu.id); // Obtenemos el color para cada menú
            return { ...menu, color };
          })
        );

        setMenus(updatedResponse);
        const schedulesTransformed = reverseTransformData(updatedResponse);
        setSchedules(schedulesTransformed);
        setLoading(true);
      } catch (error) {
        console.log("Error al obtener los menús:", error);
      }
    };

    fetchMenusWithColors();
  }, []);

  const transformDataByMenu = (data, timezone) => {
    const groupedByMenu = {};

    data.forEach((item) => {
      const { menuId } = item;

      // Inicializar el menú con un array vacío para `days` si no existe en groupedByMenu
      if (!groupedByMenu[menuId]) {
        groupedByMenu[menuId] = {
          id: menuId,
          schedule: {
            days: [],
            timezone: timezone,
          },
        };
      }

      // Iterar sobre todas las claves del item (que corresponden a los días)
      Object.keys(item).forEach((key) => {
        if (key !== "menuId") {
          let { startTime, endTime } = item[key];

          // Ajustar el endTime si es "24:00"
          if (endTime === "24:00") {
            endTime = "23:59";
          }

          // Buscar o agregar el día al menú si no existe
          let dayEntry = groupedByMenu[menuId].schedule.days.find(
            (d) => d.day === key
          );
          if (!dayEntry) {
            dayEntry = { day: key, times: [] };
            groupedByMenu[menuId].schedule.days.push(dayEntry);
          }

          // Agregar el horario al array `times` del día específico
          dayEntry.times.push({
            start_time: startTime,
            end_time: endTime,
          });
        }
      });
    });

    // Filtrar los días para mantener solo los que tienen horarios en `times`
    Object.values(groupedByMenu).forEach((menu) => {
      menu.schedule.days = menu.schedule.days.filter(
        (day) => day.times.length > 0
      );
    });

    return Object.values(groupedByMenu);
  };

  const reverseTransformData = (data) => {
    const result = [];

    data
      .filter(
        (menu) =>
          menu.schedule &&
          Array.isArray(menu.schedule.days) &&
          menu.schedule.days.length > 0
      )
      .forEach((menu) => {
        menu.schedule.days.forEach((day) => {
          if (day.times.length > 0) {
            // Crear una nueva entrada para cada intervalo de tiempo
            day.times.forEach((time) => {
              result.push({
                menuId: menu.id,
                [day.day]: {
                  startTime: time.start_time,
                  endTime: time.end_time,
                },
              });
            });
          }
        });
      });

    return result;
  };

  const handleMenuSelect = (menuId) => {
    const menu = menus.find((m) => m.id.toString() === menuId);
    setSelectedMenu(menu);
    setRangeStart(null);
    setClearMode(false);
  };

  const getScheduleStyle = (day, hour) => {
    const time = `${hour.toString().padStart(2, "0")}:00`;
    const relevantSchedules = schedules.filter(
      (schedule) =>
        schedule[day] &&
        schedule[day].startTime <= time &&
        schedule[day].endTime > time
    );

    if (relevantSchedules.length > 0) {
      const menu = menus.find((m) => m.id === relevantSchedules[0].menuId);
      return { backgroundColor: menu.color };
    }

    return {};
  };

  const isRangeOverlapping = (startDay, endDay, startHour, endHour) => {
    for (
      let d = Math.min(startDay, endDay);
      d <= Math.max(startDay, endDay);
      d++
    ) {
      const currentDay = daysOfWeek[d];
      for (let h = startHour; h <= endHour; h++) {
        if (isCellPainted(currentDay, h)) {
          return true;
        }
      }
    }
    return false;
  };

  const handleCellClick = (day, hour) => {
    if (!selectedMenu) return;

    if (!rangeStart) {
      setRangeStart({ day, hour });
      if (
        isCellPainted(day, hour) &&
        schedules.some((s) => s[day] && s.menuId === selectedMenu.id)
      ) {
        setClearMode(true);
      }
    } else {
      const startDay = daysOfWeek.indexOf(rangeStart.day);
      const endDay = daysOfWeek.indexOf(day);
      const startHour = Math.min(rangeStart.hour, hour);
      const endHour = Math.max(rangeStart.hour, hour);

      if (clearMode) {
        setSchedules((prevSchedules) =>
          prevSchedules.filter((schedule) => {
            if (schedule.menuId !== selectedMenu.id) return true;
            for (
              let d = Math.min(startDay, endDay);
              d <= Math.max(startDay, endDay);
              d++
            ) {
              const currentDay = daysOfWeek[d];
              if (schedule[currentDay]) {
                const scheduleStart = parseInt(
                  schedule[currentDay].startTime.split(":")[0]
                );
                const scheduleEnd = parseInt(
                  schedule[currentDay].endTime.split(":")[0]
                );
                if (scheduleStart < endHour + 1 && scheduleEnd > startHour) {
                  return false;
                }
              }
            }
            return true;
          })
        );
        setHasChanges(true);
      } else {
        // Check if the range overlaps with any existing schedule
        if (isRangeOverlapping(startDay, endDay, startHour, endHour)) {
          return;
        } else {
          const newScheduleItem = {
            menuId: selectedMenu.id,
          };
          for (
            let d = Math.min(startDay, endDay);
            d <= Math.max(startDay, endDay);
            d++
          ) {
            const currentDay = daysOfWeek[d];
            newScheduleItem[currentDay] = {
              startTime: `${startHour.toString().padStart(2, "0")}:00`,
              endTime: `${(endHour + 1).toString().padStart(2, "0")}:00`,
            };
          }
          setSchedules([...schedules, newScheduleItem]);
          setHasChanges(true);
        }
      }

      setRangeStart(null);
      setClearMode(false);
    }
  };

  const handleMouseMove = (day, hour) => {
    setHoveredCell({ day, hour });
  };

  const handleSaveMenuSchedule = () => {
    console.log("Saving menu schedule:", schedules);
    setHasChanges(false);
    setSaving(true); // Activar estado de carga para el guardado

    // Obtener la zona horaria del navegador
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Transformar los datos de los horarios en el formato necesario
    const transformedData = transformDataByMenu(schedules, timeZone);
    console.log(transformedData);

    // Combinar los datos transformados con los menús existentes
    const mergedMenus = menus.map((menu) => {
      const matchingSchedule = transformedData.find(
        (schedule) => schedule.id === menu.id
      );

      // Crear el objeto `menuData` y añadir `schedule` solo si `matchingSchedule` existe
      const menuData = {
        ...menu,
        menu_id: menu.id,
        ...(matchingSchedule ? { schedule: matchingSchedule.schedule } : {}),
      };

      // Eliminar `schedule` si no existe `matchingSchedule`
      if (!matchingSchedule) {
        delete menuData.schedule;
      }

      return menuData;
    });

    console.log(mergedMenus);
    const errors = []; // Array para almacenar errores

    // Hacer una petición por cada objeto en mergedMenus
    Promise.all(
      mergedMenus.map((updatedMenuInfo) =>
        makeRequest(
          "POST",
          "menu_manager/edit_menu",
          updatedMenuInfo,
          "application/json",
          localStorage.getItem("token"),
          localStorage.getItem("tokenType"),
          () => {}
        ).catch((error) => {
          errors.push(error); // Almacenar error en caso de fallo
          console.error("Error al guardar el menú:", error);
        })
      )
    )
      .then(() => {
        if (errors.length > 0) {
          console.log("Errores al guardar algunos menús:", errors);
          setNotification({
            type: "error",
            message: `Hubo ${errors.length} error(es) al guardar los menús.`,
          });
        } else {
          console.log("Todos los menús se guardaron correctamente.");
          setNotification({
            type: "success",
            message: "¡Todos los menús se guardaron correctamente!",
          });
        }
        setIsNotificationOpen(true); // Abrir el Dialog al terminar el guardado
      })
      .finally(() => {
        setSaving(false); // Desactivar estado de carga al finalizar todas las peticiones
      });
  };

  const handleClearSelectedMenu = () => {
    if (!selectedMenu) {
      return;
    }

    setSchedules((prevSchedules) =>
      prevSchedules.filter((schedule) => schedule.menuId !== selectedMenu.id)
    );
    setHasChanges(true);
  };

  const handleClearAllSchedules = () => {
    setSchedules([]);
    setHasChanges(true);
    console.log("clear");
  };

  const isCellPainted = (day, hour) => {
    const time = `${hour.toString().padStart(2, "0")}:00`;
    return schedules.some(
      (schedule) =>
        schedule[day] &&
        schedule[day].startTime <= time &&
        schedule[day].endTime > time
    );
  };

  const formatCellTime = (hour) => {
    const hourFormatted = hour % 12 || 12;
    const amPm = hour < 12 ? "AM" : "PM";
    return `${hourFormatted}:00 ${amPm}`;
  };

  return (
    <TooltipProvider>
      {loading ? (
        <div className="flex flex-col h-screen p-6 text-white">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-3xl font-bold text-primary">
              Weekly Menu Scheduler
            </h1>
            {hasChanges && (
              <SendButton onClick={handleSaveMenuSchedule}>
                Save Changes
              </SendButton>
            )}
          </div>
          <div className="flex flex-1 gap-6 overflow-auto lg:overflow-visible">
            <div className="flex-1 flex flex-col gap-4 min-w-[800px] min-h-[600px]">
              <Card className="text-white">
                <CardHeader>
                  <CardTitle>Scheduling</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="flex flex-col gap-4">
                    <div className="flex gap-4">
                      <div className="w-1/2">
                        <Label htmlFor="menu-select">Select Menu</Label>
                        <Select onValueChange={handleMenuSelect}>
                          <SelectTrigger className="w-full text-white bg-gray-700 border-gray-600">
                            <SelectValue
                              placeholder="Select a menu"
                              className="text-gray-400"
                            >
                              {selectedMenu && (
                                <div className="flex items-center text-white">
                                  <div
                                    className="w-4 h-4 rounded-full mr-2"
                                    style={{
                                      backgroundColor: selectedMenu.color,
                                    }}
                                  ></div>
                                  {selectedMenu.name}
                                </div>
                              )}
                            </SelectValue>
                          </SelectTrigger>
                          <SelectContent className="bg-gray-800 text-white border border-gray-700">
                            {menus.map((menu) => (
                              <SelectItem
                                key={menu.id}
                                value={menu.id.toString()}
                                className="focus:bg-gray-700 focus:text-white text-gray-200 hover:text-white"
                              >
                                <div className="flex items-center">
                                  <div
                                    className="w-4 h-4 rounded-full mr-2"
                                    style={{ backgroundColor: menu.color }}
                                  ></div>
                                  {menu.name}
                                </div>
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </div>
                      <div className="w-1/2">
                        <h3 className="text-sm font-semibold mb-2">
                          Menu Legend
                        </h3>
                        <div className="grid grid-cols-2 gap-x-0.5">
                          {menus.map((menu) => (
                            <div
                              key={menu.id}
                              className="flex items-center space-x-2 mb-1"
                            >
                              <div
                                className="w-4 h-4 rounded-full flex-shrink-0"
                                style={{ backgroundColor: menu.color }}
                              ></div>
                              <span className="text-sm text-white truncate">
                                {menu.name}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-2">
                      <CloseButton
                        onClick={handleClearSelectedMenu}
                        disabled={!selectedMenu}
                      >
                        Clear Selected Menu
                      </CloseButton>
                      <CloseButton onClick={handleClearAllSchedules}>
                        Clear All Menus
                      </CloseButton>
                    </div>
                  </div>
                </CardContent>
              </Card>
              <Card className="flex-1 text-white overflow-hidden">
                <CardHeader>
                  <CardTitle>Weekly Schedule</CardTitle>
                </CardHeader>
                <CardContent className="p-0">
                  <div className="overflow-x-auto">
                    <div className="inline-flex min-w-max">
                      <div className="sticky left-0 z-10 bg-[#080424]">
                        <div className="w-24 h-5"></div>
                        {daysOfWeek.map((day) => (
                          <div
                            key={day}
                            className="h-10 w-24 flex items-center justify-end text-xs text-gray-400 pr-2 border-r border-gray-600"
                          >
                            {day}
                          </div>
                        ))}
                      </div>
                      <div
                        className="inline-flex flex-col"
                        ref={scheduleGridRef}
                      >
                        <div className="flex">
                          {Array.from({ length: 24 }).map((_, hour) => (
                            <div
                              key={`time-${hour}`}
                              className="w-[3.75rem] h-5 flex items-center justify-center text-[11px] text-gray-400 border-b border-gray-600"
                            >
                              {formatCellTime(hour)}
                            </div>
                          ))}
                        </div>
                        {daysOfWeek.map((day) => (
                          <div key={day} className="flex">
                            {Array.from({ length: 24 }).map((_, hour) => (
                              <div
                                key={`${day}-${hour}`}
                                className="w-[3.75rem] h-10 border-r border-b border-gray-600 cursor-pointer relative"
                                style={getScheduleStyle(day, hour)}
                                onClick={() => handleCellClick(day, hour)}
                                onMouseMove={() => handleMouseMove(day, hour)}
                              >
                                {hoveredCell &&
                                  hoveredCell.day === day &&
                                  hoveredCell.hour === hour && (
                                    <span className="absolute inset-0 flex items-center justify-center text-[9px] font-medium bg-black bg-opacity-50 text-white select-none">
                                      {formatCellTime(hour)}
                                    </span>
                                  )}
                                {rangeStart &&
                                  rangeStart.day === day &&
                                  rangeStart.hour === hour && (
                                    <span className="absolute top-0 left-0 bg-primary text-primary-foreground text-[9px] p-0.5 rounded-br select-none">
                                      {clearMode ? "Clear start" : "Start time"}
                                    </span>
                                  )}
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
          {saving && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <CircularProgress />
            </div>
          )}
        </div>
      ) : (
        <div> loading.. </div>
      )}
      <Dialog open={showTutorial} onOpenChange={setShowTutorial}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Welcome to Weekly Menu Scheduler</DialogTitle>
            <DialogDescription>
              Here's a quick guide on how to use the scheduler:
            </DialogDescription>
          </DialogHeader>
          <div className="py-4">
            <TutorialAnimation />
          </div>
          <DialogFooter>
            <Button onClick={() => setShowTutorial(false)}>Got it!</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <Dialog open={isNotificationOpen} onOpenChange={setIsNotificationOpen}>
        <DialogContent className="max-w-md bg-gray-800 rounded-lg shadow-lg">
          <DialogHeader>
            <DialogTitle className="text-xl font-semibold">
              {notification?.type === "error" ? "Error" : "Success"}
            </DialogTitle>
          </DialogHeader>
          <div className="p-4 text-white text-lg">{notification?.message}</div>
          <DialogFooter>
            <Button onClick={() => setIsNotificationOpen(false)}>Cerrar</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </TooltipProvider>
  );
}
