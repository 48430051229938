import { useState, useEffect } from "react";
import Image from "../../../../components/tailwind_componentes/Image";
import { ChevronDown, Utensils, ShoppingCart, User } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { makeRequest } from "../../../../assets/functions_helper";
import { Box, CircularProgress } from "@mui/material";
import {
  CustomDialog,
  CustomDialogContent,
  CustomDialogTitle,
} from "../../../../components/customDialog";
import { CloseButton, SendButton } from "../../../../components/buttons";
import { useParams } from "react-router-dom";
import { ArrowLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";

export default function MenuClients() {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [openDetailProduct, setOpenDetailProduct] = useState(false);
  const [cart, setCart] = useState([]);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [showAddedAnimation, setShowAddedAnimation] = useState(false);
  const [showOrderReceivedAnimation, setShowOrderReceivedAnimation] =
    useState(false);
  const [showErrorAnimation, setShowErrorAnimation] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [loading, setLoading] = useState(true);
  const [menu, setMenu] = useState([]);

  let { tableNumber } = useParams();

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Navega a la página anterior
  };

  const addToCart = (product) => {
    setCart((prevCart) => {
      const existingItem = prevCart.find(
        (item) => item.product_id === product.product_id
      );
      if (existingItem) {
        return prevCart.map((item) =>
          item.product_id === product.product_id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      }
      return [...prevCart, { ...product, quantity: 1 }];
    });
    console.log(product);
    console.log(cart);

    setShowAddedAnimation(true);
    setSelectedProduct(null);
  };

  const removeFromCart = (productId) => {
    setCart((prevCart) => prevCart.filter((item) => item.id !== productId));
  };

  const getTotalCost = () => {
    if (cart.length !== 0) {
      return cart
        .reduce(
          (total, item) => total + parseFloat(item.price) * item.quantity,
          0
        )
        .toFixed(2);
    }
  };

  const callWaiter = () => {
    let obj = {
      table_public_id: tableNumber ? tableNumber : "3test",
      code: localStorage.getItem("tableCode"),
      comment: "",
      latitude: 12,
      longitude: 12,
      call_type: "normal_call",
      order: {
        products: cart,
        total_price: cart
          .reduce(
            (total, item) => total + parseFloat(item.price) * item.quantity,
            0
          )
          .toFixed(2),
      },
    };
    makeRequest(
      "POST",
      `notify_waiters/${tableNumber ? tableNumber : "3test"}`,
      obj,
      "application/json",
      localStorage.getItem("token"),
      localStorage.getItem("tokenType"),
      () => {}
    )
      .then(() => {
        setShowOrderReceivedAnimation(true);
      })
      .catch((error) => {
        console.log("Error al obtener los menús:", error);
        setMessageError(error.message);
        setShowErrorAnimation(true);
      });
    console.log(obj);
    setCart([]);
    setIsCartOpen(false);
  };

  const renderProductSection = (title, products) => (
    <section className="mb-16">
      <h2 className="mb-8 text-center text-3xl font-bold">{title}</h2>
      <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
        {products
          .sort((a, b) => a.order - b.order)
          .map((product) => (
            <div
              key={product.id}
              className="group relative overflow-hidden rounded-lg border border-gray-700 bg-gray-800 cursor-pointer transition-transform hover:scale-105"
              onClick={() => {
                setSelectedProduct(product);
                setOpenDetailProduct(true);
              }}
            >
              <div className="aspect-video overflow-hidden">
                <Image
                  src={product.image_url}
                  alt={product.name}
                  width={600}
                  height={400}
                  className="object-cover transition-transform group-hover:scale-110"
                />
              </div>
              <div className="p-4">
                <h3 className="text-xl font-semibold">{product.name}</h3>
                <p className="mt-2 font-semibold">
                  {product.price &&
                    new Intl.NumberFormat("es-CO", {
                      style: "currency",
                      currency: "COP",
                    }).format(product.price)}
                </p>
              </div>
            </div>
          ))}
      </div>
    </section>
  );

  useEffect(() => {
    if (showAddedAnimation) {
      const timer = setTimeout(() => {
        setShowAddedAnimation(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [showAddedAnimation]);

  useEffect(() => {
    if (showOrderReceivedAnimation) {
      const timer = setTimeout(() => {
        setShowOrderReceivedAnimation(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showOrderReceivedAnimation]);

  useEffect(() => {
    if (showErrorAnimation) {
      const timer = setTimeout(() => {
        setShowErrorAnimation(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showErrorAnimation]);

  useEffect(() => {
    makeRequest(
      "POST",
      `menu_manager/public_menu?table_id=${
        tableNumber ? tableNumber : "3test"
      }`,
      {},
      "application/json",
      localStorage.getItem("token"),
      localStorage.getItem("tokenType"),
      () => {}
    )
      .then((response) => {
        const validSections = response.menu.sections
          ? [...response.menu.sections]
          : [];
        validSections.sort((a, b) => (a.order || 0) - (b.order || 0));
        setMenu({ ...response, sections: validSections });
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error al obtener los menús:", error);
      });
  }, [tableNumber]);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <div className="min-h-screen bg-gray-900 text-white">
          {/* Hero Section */}
          <div
            className="relative h-screen w-full bg-cover bg-center bg-no-repeat"
            style={{
              backgroundImage:
                "url('https://hebbkx1anhila5yf.public.blob.vercel-storage.com/wallpaperflare.com_wallpaper.jpg-PA4b0ZzXGWnybVmdhYtUbhsfKrwzaY.jpeg')",
            }}
          >
            {/* Overlay oscuro */}
            <div className="absolute inset-0 bg-black bg-opacity-50"></div>

            {/* Flecha para volver (siempre visible) */}
            <button
              onClick={handleBack}
              className="fixed top-4 left-4 z-50 p-3 bg-black/60 text-white rounded-full shadow-lg hover:bg-black/80 transition-all"
              aria-label="Go Back"
            >
              <ArrowLeft className="h-6 w-6" />
            </button>

            {/* Contenido principal */}
            <div className="relative z-10 flex flex-col items-center justify-between h-full py-10">
              <div className="text-center">
                <div className="mb-4 rounded-full bg-primary/20 p-3 inline-block">
                  <Utensils className="h-8 w-8" />
                </div>
                <h1 className="text-4xl font-bold sm:text-5xl md:text-6xl mb-4">
                  Le Gourmet
                </h1>
                <p className="text-lg text-gray-200 max-w-md mx-auto">
                  Experience culinary excellence in every bite...
                </p>
              </div>
              <div className="animate-bounce">
                <ChevronDown className="h-8 w-8" />
              </div>
            </div>
          </div>

          {/* Menu Sections */}
          <div className="bg-gray-900">
            <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
              {menu.sections.map((section) =>
                renderProductSection(section.name, section.products)
              )}
            </div>
          </div>

          {/* Product Details Modal */}
          <CustomDialog
            open={openDetailProduct}
            onClose={() => setOpenDetailProduct(!openDetailProduct)}
          >
            <CustomDialogContent className="sm:max-w-[425px]">
              <CustomDialogTitle>
                <h2 className="text-xl">{selectedProduct?.name}</h2>
              </CustomDialogTitle>
              <div className="mt-4">
                <Image
                  src={selectedProduct?.image_url || ""}
                  alt={selectedProduct?.name || ""}
                  width={400}
                  height={300}
                  className="rounded-lg object-cover w-full"
                />
                <p className="text-sm text-gray-300">
                  {selectedProduct?.description}
                </p>
                <p className="mt-2 font-semibold">
                  Precio:{" "}
                  {selectedProduct?.price &&
                    new Intl.NumberFormat("es-CO", {
                      style: "currency",
                      currency: "COP",
                    }).format(selectedProduct.price)}
                </p>
                <SendButton
                  className="mt-4 w-full"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (selectedProduct) addToCart(selectedProduct);
                    setOpenDetailProduct(false);
                  }}
                >
                  <User className="mr-2 h-4 w-4" /> Add to Cart
                </SendButton>
              </div>
            </CustomDialogContent>
          </CustomDialog>

          {/* Cart Icon */}
          <div
            className="fixed bottom-4 right-4 bg-primary text-primary-foreground rounded-full p-3 cursor-pointer shadow-lg"
            onClick={() => setIsCartOpen(true)}
          >
            <ShoppingCart className="h-6 w-6" />
            {cart.length > 0 && (
              <span className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center text-xs">
                {cart.reduce((total, item) => total + item.quantity, 0)}
              </span>
            )}
          </div>

          {/* Cart Modal */}
          <CustomDialog
            open={isCartOpen}
            onClose={() => setIsCartOpen(!isCartOpen)}
          >
            <CustomDialogContent>
              <CustomDialogTitle>Your Cart</CustomDialogTitle>
              <div className="mb-4">
                {cart.length === 0 ? (
                  <p>Your cart is empty.</p>
                ) : (
                  <>
                    {cart.map((item) => {
                      const price = item.price ? parseFloat(item.price) : 0;

                      return (
                        <div
                          key={item.id}
                          className="flex justify-between items-center mb-6 border-b border-gray-200 pb-4"
                        >
                          <div className="flex-1 space-y-1">
                            <span className="font-semibold text-lg">
                              {item.name}
                            </span>
                            <span className="text-sm text-gray-500 ml-2">
                              x{item.quantity}
                            </span>
                          </div>
                          <div className="flex items-center space-x-6">
                            <span className="w-32 text-right text-lg font-medium">
                              ${(price * item.quantity).toFixed(2)}
                            </span>
                            <CloseButton
                              variant="destructive"
                              size="sm"
                              className="ml-4 text-sm"
                              onClick={() => removeFromCart(item.id)}
                            >
                              Remove
                            </CloseButton>
                          </div>
                        </div>
                      );
                    })}
                    <div className="mt-6 text-lg font-bold flex justify-between items-center">
                      <span>Total:</span>
                      <span>${getTotalCost()}</span>
                    </div>
                    <SendButton
                      className="w-full mt-6"
                      onClick={() => callWaiter()}
                    >
                      Call Waiter
                    </SendButton>
                  </>
                )}
              </div>
            </CustomDialogContent>
          </CustomDialog>

          <AnimatePresence>
            {showAddedAnimation && (
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 50 }}
                className="fixed bottom-20 right-4 bg-green-500 text-white p-3 rounded-lg shadow-lg"
              >
                Item added to cart!
              </motion.div>
            )}
          </AnimatePresence>

          <AnimatePresence>
            {showOrderReceivedAnimation && (
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 50 }}
                className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-green-500 text-white p-6 rounded-lg shadow-lg text-center"
              >
                <p className="text-xl font-bold mb-2">Order Received!</p>
                <p>A waiter will be with you shortly.</p>
              </motion.div>
            )}
          </AnimatePresence>

          <AnimatePresence>
            {showErrorAnimation && (
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 50 }}
                className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-red-500 text-white p-6 rounded-lg shadow-lg text-center"
              >
                <p className="text-xl font-bold mb-2">Error!</p>
                <p>{messageError}</p>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      )}
    </>
  );
}
